export const Dashboard = {
  Tabs: {
    home: "Início",
    products: "Produtos",
    sales: "Vendas",
    reports: "Relatórios",
    extras: "Extras",
    invoices: "Pagamentos",
    subscriptions: "Assinaturas",
    clients: "Clientes",
    affiliates: "Afiliados",
    coupons: "Cupons",
    refunds: "Reembolsos",
    bankStatement: "Extrato",
    wallet: "Financeiro",
    waitlist: "Lista de espera",
    activity: "Atividade",
    members: "Membros",
    referral: "Indicações",
    recurrence: "Recorrência",
    operation: "Operacional",
    integrations: "Integrações",
  },
  Invoice: {
    popup: "Deseja gerar uma segunda via? Ela será automaticamente enviada ao email do comprador.",
    invoice: "Fatura",
    client: "Cliente",
    email: "Email",
    cellphone: "Telefone",
    creationDate: "Data de criação",
    expirationDate: "Vencimento",
    group: "Grupo",
    paymentStatus: "Status do pagamento",
    paymentMethod: "Método de pagamento",
    eventType: "Tipo de pagamento",
    affiliate: "Afiliado",
    subscription: "Assinatura",
    history: "Histórico",
    date: "Data",
    description: "Descrição",
    notes: "Notas",
    button: {
      refund: "Estornar",
      newInvoice: "Segunda via",
      cancel: "Cancelar",
    },
    EmptyState: "Você não possui nenhuma transação nesse período.",
    total: "Valor do Produto",
    taxes: "Taxa da Hubla",
    affiliateCommission: "Comissão de Afiliado",
    coupon: "Cupom de Desconto",
    amount: "Valor Líquido",
    paymentData: "Dados do pagamento",
    product: "Produto",
  },
  Refund: {
    title: "Pedido de reembolso",
    client: "Cliente",
    email: "Email",
    cellphone: "Telefone",
    subscriptionRenewal: "Renovação de assinatura?",
    yes: "Sim",
    no: "Não",
    status: "Status",
    group: "Grupo",
    payedIn: "Pago em",
    requestedIn: "Pedido em",
    paymentMethod: "Método de pagamento",
    reason: "Motivo",
    error: "Erro",
    button: {
      refund: "Reembolso",
      refuse: "Recusar",
    },
  },
  Subscription: {
    title: "Assinatura",
    client: "Cliente",
    plan: "Plano",
    nextPayment: "Próximo pagamento",
    pendingPayment: "Pagamento da renovação pendente.",
    startAt: "Início Da Assinatura",
    subscriptionStatus: "Status Da Assinatura",
    invoices: "Faturas",
    expiresAt: "Vencimento",
    status: "Situação",
    price: "Preço",
    detail: "Detalhes",
    see: "Ver",
    history: "Histórico",
    date: "Data",
    description: "Descrição",
    notes: "Notas",
    created: "Criado",
    button: {
      activate: "Ativar",
      suspend: "Suspender",
    },
    error: "Erro",
    EmptyState: "Você não possui nenhuma assinatura nesse período.",
    tableTitle: {
      subscribers: "Assinantes",
      subscriber: "Assinante",
      product: "Produto",
      startedAt: "Data de início",
      dueDate: "Vencimento",
    },
    tableSearch: "Pesquise por um nome ou e-mail",
    tabs: {
      active: "Ativo",
      all: "Todos",
    },
    Detail: {
      listItems: {
        plan: "Plano:",
        status: "Status:",
        start: "Início:",
        nextPayment: "Próximo pagamento:",
        paymentMethod: "Método:",
        email: "E-mail:",
        whatsapp: "WhatsApp:",
        phoneNumber: "Telefone:",
        getInTouch: "Entrar em contato:",
        price: "Valor:",
      },
      currentStatus: {
        active: "Ativa",
        inactive: "Inativa",
      },
      transactionStatus: {
        pending: "Pendente",
        processing: "Processamento",
        registered: "Registrado",
        none: "Nenhum",
        paid: "Pago",
        authorized: "Autorizado",
        canceled: "Cancelado",
        partiallyPaid: "Parcialmente pago",
        refunded: "Reembolsado",
        expired: "Expirada",
        inProtest: "Em protesto",
        chargeback: "Estorno",
        inAnalysis: "Em análise",
        draft: "Rascunho",
      },
      tableTitle: {
        date: "Data",
        receipt: "Status",
      },
    },
    ReasonWhyCantChangePlan: {
      title: " ",
      canceledOrSuspended: "A troca de planos está indisponível porque a sua assinatura está ",
      noPlansAvailable: "A troca de planos está indisponível porque não existem planos disponíveis para troca",
      expiresAt: "A troca de planos só estará disponível após o dia ",
      featureFlag: "A troca de planos está indisponível para este produto",
      isChangingPlan:
        "A troca de planos está indisponível porque uma troca de planos já está acontecendo neste momento",
    },
  },
  SubscriptionDetails: {
    title: "Detalhes de assinatura",
    by: "Por",
    plan: "Plano",
    endAt: "Término da assinatura",
    renewalDate: "Data de renovação",
    subscriptionStartedAt: "Assinatura iniciada em",
    changePaymentMethod: "Alterar método de pagamento",
    changePlan: "Alterar plano de assinatura",
    refund: "Solicitar reembolso",
    cancel: "Cancelar assinatura",
    renewSubscription: "Renovar assinatura",
    suspended: "Assinatura suspensa",
    pixIsAutogenerated: "O código Pix é gerado automaticamente para o pagamento da renovação.",
    table: {
      date: "Data",
      method: "Método",
      status: "Status",
      amount_charged: "Valor cobrado",
    },
    transaction: {
      scheduled: "Agendado",
      paid: "Pago",
      requested_refund: "Reembolso solicitado",
      refunded: "Reembolso concluído",
      suspended: "Suspenso",
      canceled: "Cancelado",
    },
  },
  SaleItem: {
    sale: "Venda",
    subscription: "Assinante",
  },
  Button: {
    newProduct: "Criar um novo produto",
  },
  Waitlist: {
    Empty: {
      Message:
        "Você ainda não possui contatos na lista de espera. Compartilhe a sua página para captar novos contatos.",
      Button: "Copiar link",
    },

    Table: {
      Product: "Produto",
      Contacts: "Contatos",
      Conversions: "Conversões",
    },
    GroupStatus: {
      isDraft: {
        current: "Rascunho",
        action: "Colocar em rascunho",
        description: "",
      },
      isPublished: {
        current: "Publicado",
        action: "Publicar",
        description: "",
      },
      isSelling: {
        current: "Vendendo",
        action: "Ativar vendas",
        description: "Pessoas poderão encontrar e comprar o seu produto",
      },
      isWaitlisted: {
        current: "Em lista de espera",
        action: "Ativar lista de espera",
        description: "Pessoas poderão encontrar o seu produto e se inscrever na lista de espera",
      },
      isNotEnabled: {
        current: "Desativado",
        action: "Desativar",
        description: "Você irá desativar as vendas do seu produto e escondê-lo do seu perfil",
      },
      isNotSelling: {
        current: "Vendas encerradas",
        action: "Encerrar vendas",
        description: "Você irá desativar as vendas do seu produto e escondê-lo do seu perfil",
      },
      isUnavailable: {
        current: "Temporariamente indisponível",
        action: "",
      },
    },
    EmptyMessage: {
      active: "Você ainda não têm nenhuma lista de espera ativa. Para criar uma lista de espera, acesse Meus Produtos.",
      inactive: "Você não têm nenhuma lista de espera inativa",
    },
    Tabs: { Active: "Ativas", Inactive: "Inativas" },
    Detail: {
      HeadInfo: {
        conversionsAmount: "Convertidos",
        subscribersAmount: "Contatos",
      },
      Header: {
        Subtitle: "Criado dia ",
      },
      Dropbox: {
        allSubscribers: "Todos contatos",
        convertedMember: "Convertidos",
        notMember: "Não convertidos",
      },
      Table: {
        Name: "Nome",
        PhoneNumber: "Telefone",
        Email: "E-mail",
        Date: "Data",
        Conversion: "Conversão",
        Status: "Status",
        Action: "Ações",
        Row: {
          Status: {
            notMember: "Não convertido",
            convertedMember: "Convertido",
          },
        },
      },
      PopupTips: "Apresenta o primeiro grupo que o membro comprou após entrar na lista de espera.",
    },
    Title: "Lista de espera",
    Subscribers: " Inscritos",
    Conversions: " Conversões",
    Subscriber: " Inscrito",
    Conversion: " Convertido",
  },

  productsTab: {
    yourProducts: "Seus produtos",
    productsImPartner: "Produtos que sou parceiro",
    affiliates: "Minhas afiliações",
  },

  affiliatesTab: {
    status: {
      pending: "Pendente",
      enabled: "Ativo",
      disabled: "Inativo",
    },
  },
}
