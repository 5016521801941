import "reflect-metadata"
import { Analytics } from "@chatpay/components"
import * as React from "react"
import { CookiesProvider } from "react-cookie"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import configureStore from "./base/Store"
import App from "./components/App"
import "./i18n"
import "./components/Theme/index.scss"
import * as serviceWorker from "./serviceWorker"
import * as OfflinePluginRuntime from "offline-plugin/runtime"
import { QueryClientProvider } from "@tanstack/react-query"
import { ThemeProvider, theme } from "@hub-la/soad"
import { FlagsProvider } from "hooks"
import { queryClient } from "query-client"
import { ContainerProvider } from "hooks/use-container"
import { ContainerProvider as GlobalContainerProvider, container as globalContainer } from "@hub-la/fe-container"
import container from "container"
import { VideosUploader, VideosUploaderProvider } from "@hub-la/fe-post"
import { ThemeProvider as DsThemeProvider, theme as dsTheme } from "@hub-la/design-system"
import { RoleplayPermissionProvider, RoleplayModeAlert, RoleplayProvider } from "@hub-la/fe-roleplay"

const store = configureStore()
Analytics.init()

const app = document.getElementById("root")
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(app!)
root.render(
  <CookiesProvider>
    <QueryClientProvider client={queryClient}>
      <GlobalContainerProvider container={globalContainer}>
        <ContainerProvider container={container}>
          <Provider store={store}>
            <ThemeProvider theme={theme.light}>
              <FlagsProvider>
                <VideosUploaderProvider>
                  <RoleplayPermissionProvider>
                    <RoleplayProvider>
                      <RoleplayModeAlert />
                    </RoleplayProvider>
                    <App />
                    <DsThemeProvider theme={dsTheme.light}>
                      <VideosUploader />
                    </DsThemeProvider>
                  </RoleplayPermissionProvider>
                </VideosUploaderProvider>
              </FlagsProvider>
            </ThemeProvider>
          </Provider>
        </ContainerProvider>
      </GlobalContainerProvider>
    </QueryClientProvider>
  </CookiesProvider>,
)

serviceWorker.unregister()

OfflinePluginRuntime.install()
